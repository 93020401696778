import { RootState } from "app/store";

import type { AppStartListening } from "types/AppStartListening";

import { liveStreamingActions, liveStreamingSlice } from "./slice";

import { coreActions } from "features/core/store/slice";
import { sitesActions } from "features/sites/store/slice";
import { getSiteDeviceSelectionBySiteId } from "./helpers";
import { messagesActions } from "features/messages/store/slice";
import { LiveStreamingClientMethodNames } from "features/messages/hubs/LiveStreamingHub";
import { StreamQualityType } from "types/StreamQualityType";

// listeners
export const addLiveStreamingListeners = (startListening: AppStartListening) => {
    // when a user selects a site using search field in header for example
    startListening({
        actionCreator: sitesActions.selectSite,
        effect: async (action, listenerApi) => {
            // set the site as active in the slice state
            listenerApi.dispatch(
                liveStreamingActions.setSiteAsActive({
                    siteId: action.payload.id,
                }),
            );
        },
    });

    // when a device gets selected/deselected
    startListening({
        actionCreator: liveStreamingSlice.actions.setDeviceSelectionState,
        effect: async (action, listenerApi) => {
            const state = listenerApi.getState() as RootState;

            if (!state.liveStreaming.activeSiteId) return;

            if (action.payload.selected) {
                // new device selected set as fullscreen device
                listenerApi.dispatch(
                    liveStreamingSlice.actions.setFullscreenDeviceId({
                        siteId: state.liveStreaming.activeSiteId,
                        deviceId: action.payload.deviceId,
                    }),
                );
            } else {
                // device removed from selection
                // select new device if currently the fullscreen device
                if (state.liveStreaming.fullscreenDeviceId === action.payload.deviceId) {
                    const siteDeviceSelection = getSiteDeviceSelectionBySiteId(state.liveStreaming, state.liveStreaming.activeSiteId);

                    if (!siteDeviceSelection?.selectedDevices?.length && state.core.uiMode === "fullscreen") {
                        // exit fullscreen mode
                        listenerApi.dispatch(coreActions.setUIMode("window"));
                    } else {
                        // select next device
                        listenerApi.dispatch(liveStreamingSlice.actions.setNextFullscreenDeviceId());
                    }
                }
            }
        },
    });

    // Listen for Quality Level changed messages
    startListening({
        predicate: (action) => {
            return messagesActions.messageReceived.match(action) && action.payload.messageType === LiveStreamingClientMethodNames.DeviceLiveStreamUpdated;
        },
        effect: async (action, listenerApi) => {
            if (messagesActions.messageReceived.match(action)) {
                const [deviceId, streamQuality] = action.payload.args as [string, StreamQualityType];

                await listenerApi.dispatch(
                    liveStreamingActions.setSelectedDeviceStreamQuality({
                        deviceId,
                        streamQuality,
                    }),
                );
            }
        },
    });
};
