import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { PaletteMode } from "@mui/material";

import type { UIMode } from "types/UIMode";

import { initialState } from "./state";

// actions
export const coreSlice = createSlice({
    name: "core",
    initialState,
    reducers: {
        setThemePaletteMode: (state, action: PayloadAction<PaletteMode>) => {
            state.themePaletteMode = action.payload;
        },
        setUIMode: (state, action: PayloadAction<UIMode>) => {
            state.uiMode = action.payload;
        },
        setHideNavigationalElements: (state, action: PayloadAction<boolean>) => {
            state.hideNavigationalElements = action.payload;
        },
        setShowSidebar: (state, action: PayloadAction<{ show: boolean }>) => {
            state.showSidebar = action.payload.show;
        },
        setLastWindowModeYOffset: (state, action: PayloadAction<number>) => {
            state.lastWindowModeYOffset = action.payload;
        },
    },
});

// exports
export const coreActions = coreSlice.actions;

export default coreSlice.reducer;
