import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { AppFeature } from "features/appFeatures/types/AppFeature";
import { MatchType } from "features/appFeatures/types/matchType";
import { selectActiveSiteId } from "features/liveStreaming/store/selectors";
import { playbackSelectionSelectors } from "features/playback/store/selectors.selection";
import { RouteHandleDocumentTitleExtraInfo } from "types/RouteHandleDocumentTitleExtraInfo";
import { FeatureFlagGuard } from "features/appFeatures/FeatureFlagGuard";

export const createRouter = (drawerWidth: number) =>
    createBrowserRouter([
        {
            path: "/",
            element: <App />,
            children: [
                {
                    path: "/",
                    async lazy() {
                        const { Home } = await import("./features/home/Home");

                        return {
                            element: (
                                <FeatureFlagGuard accessibleWithFeatureFlags={[AppFeature.LiveStreaming, AppFeature.Playback]} matchType={MatchType.Any} withAuthentication>
                                    <Home />
                                </FeatureFlagGuard>
                            ),
                        };
                    },
                },
                {
                    handle: {
                        getDocumentTitleExtraInfoSelector: () => selectActiveSiteId,
                    } as RouteHandleDocumentTitleExtraInfo,
                    index: true,
                    path: "livestreaming/:hashedvalue?",
                    async lazy() {
                        const { LiveStreaming } = await import("./features/liveStreaming/LiveStreaming");

                        return {
                            element: (
                                <FeatureFlagGuard accessibleWithFeatureFlags={[AppFeature.LiveStreaming]} withAuthentication>
                                    <LiveStreaming drawerWidth={drawerWidth} />
                                </FeatureFlagGuard>
                            ),
                        };
                    },
                },
                {
                    path: "livestreaming/permalinklanding/site/:siteId/devices/*",
                    async lazy() {
                        const { LandingPageLiveStream } = await import("./features/liveStreaming/landingpage/LandingPageLiveStream");

                        return {
                            element: <LandingPageLiveStream />,
                        };
                    },
                },
                {
                    handle: {
                        getDocumentTitleExtraInfoSelector: () => playbackSelectionSelectors.selectSiteId,
                    } as RouteHandleDocumentTitleExtraInfo,
                    path: "playback/:siteId?",
                    async lazy() {
                        const { Playback } = await import("./features/playback/Playback");

                        return {
                            element: (
                                <FeatureFlagGuard accessibleWithFeatureFlags={[AppFeature.Playback]} unauthorizedTitle={"Currently your account doesn't have the required permissions to use the playback functionality."} withAuthentication>
                                    <Playback drawerWidth={drawerWidth} />
                                </FeatureFlagGuard>
                            ),
                        };
                    },
                },
                {
                    path: "download",
                    async lazy() {
                        const { Downloads } = await import("./features/downloads/Downloads");

                        return {
                            element: (
                                <FeatureFlagGuard accessibleWithFeatureFlags={[AppFeature.PlaybackBatchDownload]} unauthorizedTitle={"Currently your account doesn't have the required permissions to use the batch download functionality."} withAuthentication>
                                    <Downloads drawerWidth={drawerWidth} />
                                </FeatureFlagGuard>
                            ),
                        };
                    },
                },
            ],
        },
    ]);
