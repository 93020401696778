import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "app/store";
import { createRouter } from "./createRouter";

const drawerWidth = 240;
const router = createRouter(drawerWidth);

// Vite: Load Error Handling - https://vitejs.dev/guide/build.html#load-error-handling
window.addEventListener("vite:preloadError", () => {
    console.warn("Vite preload error occured, reloading page...");
    window.location.reload(); // for example, refresh the page
});

// Component
ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
        </Provider>
    </React.StrictMode>,
);
