import { createAsyncThunk, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import minMax from "dayjs/plugin/minMax";

import { RootState } from "app/store";
import { initialState, recordingAdapter } from "./state";
import { playbackSessionSelectors } from "./selectors.session";
import { selectPlaybackConfiguration } from "features/config/store/selectors";

import { recordingsApi } from "services/streamingApi/recordings.api";

import { FetchRecordingsForDeviceRejectedReason } from "../types/FetchRecordingsForDeviceRejectedReason";

dayjs.extend(isBetween);
dayjs.extend(minMax);

// thunks
export const fetchRecordingsForDeviceAsync = createAsyncThunk(
    "playback/fetchRecordingsForDeviceAsync",
    async (
        payload: {
            deviceId: EntityId;
            startDateTime: string;
            endDateTime: string;
        },
        { rejectWithValue, dispatch, getState },
    ) => {
        // fetch recordings for device from API
        const { data, error, isSuccess } = await dispatch(
            recordingsApi.endpoints.getDeviceRecordings.initiate({
                deviceId: payload.deviceId,
                startTime: payload.startDateTime,
                stopTime: payload.endDateTime,
            }),
        );

        if (isSuccess) {
            // prevent exceeding of the allowed number of recordings
            const state = getState() as RootState;

            const playbackConfiguration = selectPlaybackConfiguration(state);
            const supportedNumberOfRecordings = playbackConfiguration?.supportedNumberOfRecordings || 0;
            const totalRecordings = playbackSessionSelectors.selectTotalRecordings(state);

            const newTotalOfRecordings = totalRecordings + data.length;

            if (newTotalOfRecordings <= supportedNumberOfRecordings) {
                // not exceeding threshold
                return data;
            }

            return rejectWithValue({
                supportedNumberOfRecordingsExceeded: true,
                message: `Total number of recordings (${newTotalOfRecordings}) exceeds the supported total of recordings (${supportedNumberOfRecordings})`,
            } as FetchRecordingsForDeviceRejectedReason);
        }

        return rejectWithValue({
            supportedNumberOfRecordingsExceeded: true,
            message: `Error while retrieving recordings for device ${payload.deviceId}: ${error}`,
        } as FetchRecordingsForDeviceRejectedReason);
    },
);

export const removeRecordingsAndStateForDeviceAsync = createAsyncThunk(
    "playback/removeRecordingsAndStateForDeviceAsync",
    async (
        payload: {
            deviceId: EntityId;
        },
        { getState },
    ) => {
        // fetch recordings for device from state
        const state = getState() as RootState;

        const selectRecordingsByDeviceId = playbackSessionSelectors.selectRecordingsByDeviceIdFactory();

        const recordingIds = selectRecordingsByDeviceId(state, payload.deviceId).map((recording) => recording.id);

        return recordingIds;
    },
);

// slice
export const playbackSlice = createSlice({
    name: "playback",
    initialState,
    reducers: {
        resetState: (state) => {
            state.siteId = undefined;
            state.deviceIds = [];
            state.sessionId = undefined;
            state.recordings = recordingAdapter.getInitialState();
            state.userRequestedPlayMode = "stop";
            state.recordingsReadyForPlayback = [];
            state.recordingsFailedToLoad = [];
            state.recordingsWaiting = [];
            state.continuousPlaybackStartDateTime = undefined;
            state.continuousPlaybackEndDateTime = undefined;
        },
        setSiteId: (
            state,
            action: PayloadAction<{
                siteId: EntityId;
            }>,
        ) => {
            state.siteId = action.payload.siteId;
        },
        upsertDeviceSelectionState: (
            state,
            action: PayloadAction<{
                deviceId: EntityId;
                selected: boolean;
            }>,
        ) => {
            if (action.payload.selected) {
                // new device selected
                state.deviceIds.unshift(action.payload.deviceId);

                // when we select a new device we need to invalidate continuous playback window
                state.continuousPlaybackStartDateTime = undefined;
                state.continuousPlaybackEndDateTime = undefined;
            } else if (!action.payload.selected) {
                // device deselected
                state.deviceIds = state.deviceIds.filter((x) => x !== action.payload.deviceId);
            }
        },
        setSelectedDevices: (
            state,
            action: PayloadAction<{
                deviceIds: EntityId[];
            }>,
        ) => {
            state.deviceIds = action.payload.deviceIds;
        },
        setStartAndEndDateTime: (
            state,
            action: PayloadAction<{
                startDateTime: string;
                endDateTime: string;
            }>,
        ) => {
            state.startDateTime = action.payload.startDateTime;
            state.endDateTime = action.payload.endDateTime;

            // reset loaded recordings
            recordingAdapter.removeAll(state.recordings);
        },

        // session
        setSessionId: (
            state,
            action: PayloadAction<{
                sessionId: string;
            }>,
        ) => {
            state.sessionId = action.payload.sessionId;
        },
        resetSessionId: (state) => {
            state.sessionId = undefined;
        },
        resetRecordingsPlaybackState: (state) => {
            state.recordingsReadyForPlayback = [];
            state.recordingsFailedToLoad = [];
            state.recordingsWaiting = [];

            if (state.userRequestedPlayMode === "resume") {
                state.userRequestedPlayMode = "play";
            }
        },
        setElapsedPlayTimeInMs: (state, action: PayloadAction<{ elapsedPlayTimeInMs: number }>) => {
            state.playbackElapsedTimeInMs = action.payload.elapsedPlayTimeInMs;

            // determine if we need to update to continuous playing state
            if (!state.continuousPlaybackStartDateTime) {
                // there is no currently running continuous playback state
                state.continuousPlaybackStartDateTime = state.playbackStartDateTime;
            }

            const currentPlaybackEndDateTime = dayjs(state.playbackStartDateTime).add(state.playbackElapsedTimeInMs, "milliseconds");

            const maxPlayedEndDateTime = dayjs.max(currentPlaybackEndDateTime, dayjs(state.continuousPlaybackEndDateTime || 0));

            if (maxPlayedEndDateTime) {
                state.continuousPlaybackEndDateTime = maxPlayedEndDateTime.toISOString();
            }
        },
        startPlayback: (state) => {
            let newStartPos;

            if (!state.playbackStartDateTime) {
                newStartPos = state.startDateTime;
            } else {
                newStartPos = dayjs(state.playbackStartDateTime).add(state.playbackElapsedTimeInMs, "milliseconds").toISOString();
            }

            state.userRequestedPlayMode = state.userRequestedPlayMode === "pause" ? "resume" : "play";

            if (!state.continuousPlaybackStartDateTime && state.userRequestedPlayMode === "resume") {
                // when we resume playback we should create a continous playback window so we build on previously buffered data
                state.continuousPlaybackStartDateTime = state.playbackStartDateTime;
                state.continuousPlaybackEndDateTime = newStartPos;
            }

            state.playbackStartDateTime = newStartPos;
            state.playbackElapsedTimeInMs = 0;
        },
        saveCurrentPlaybackPosition: (state) => {
            const currentPlaybackEndDateTime = dayjs(state.playbackStartDateTime).add(state.playbackElapsedTimeInMs, "milliseconds");

            state.playbackStartDateTime = currentPlaybackEndDateTime.toISOString();
            state.playbackElapsedTimeInMs = 0;
            state.playbackStartDateTimeChangedTimestamp = dayjs().unix();
        },
        setPlaybackStartDateTime: (state, action: PayloadAction<{ playbackStartDateTime: string }>) => {
            // reset continuous playback window if new position is out of the current window
            const currentPlaybackEndDateTime = dayjs(state.playbackStartDateTime).add(state.playbackElapsedTimeInMs, "milliseconds");

            const maxPlayedEndDateTime = dayjs.max(currentPlaybackEndDateTime, dayjs(state.continuousPlaybackEndDateTime || 0));

            const requestedStartDateTime = dayjs(action.payload.playbackStartDateTime);

            const isInContinousPlaybackWindow = requestedStartDateTime.isBetween(state.continuousPlaybackStartDateTime, maxPlayedEndDateTime, "seconds", "[]");

            if (!isInContinousPlaybackWindow) {
                // we can't use the continuous playback state, so let's start another window
                state.continuousPlaybackStartDateTime = action.payload.playbackStartDateTime;
                state.continuousPlaybackEndDateTime = undefined;
            }

            // new values
            state.playbackStartDateTime = action.payload.playbackStartDateTime;
            state.playbackElapsedTimeInMs = 0;
            state.playbackStartDateTimeChangedTimestamp = dayjs().unix();

            if (state.userRequestedPlayMode === "pause") {
                state.userRequestedPlayMode = "stop";
            }
        },
        pausePlayback: (state) => {
            state.userRequestedPlayMode = "pause";
        },
        stopPlayback: (state) => {
            state.userRequestedPlayMode = "stop";
            state.playbackStartDateTime = undefined;
        },
        addSecondsToPlaybackStartDateTime: (state, action: PayloadAction<{ seconds: number }>) => {
            const newDateTime = dayjs(state.playbackStartDateTime).add(action.payload.seconds, "seconds");

            state.playbackStartDateTime = newDateTime.toISOString();
            state.playbackElapsedTimeInMs = 0;
        },
        subtractSecondsFromPlaybackStartDateTime: (state, action: PayloadAction<{ seconds: number }>) => {
            const newDateTime = dayjs(state.playbackStartDateTime).subtract(action.payload.seconds, "seconds");

            state.playbackStartDateTime = newDateTime.toISOString();
            state.playbackElapsedTimeInMs = 0;
        },
        setRecordingReadyForPlayback: (state, action: PayloadAction<{ recordingId: EntityId }>) => {
            state.recordingsReadyForPlayback.push(action.payload.recordingId);

            state.recordingsFailedToLoad = state.recordingsFailedToLoad.filter((x) => x !== action.payload.recordingId);
            state.recordingsWaiting = state.recordingsWaiting.filter((x) => x !== action.payload.recordingId);
        },
        setRecordingFailedToLoad: (state, action: PayloadAction<{ recordingId: EntityId }>) => {
            state.recordingsFailedToLoad.push(action.payload.recordingId);
            state.recordingsReadyForPlayback = state.recordingsReadyForPlayback.filter((x) => x !== action.payload.recordingId);
            state.recordingsWaiting = state.recordingsWaiting.filter((x) => x !== action.payload.recordingId);
        },
        setRecordingWaiting: (state, action: PayloadAction<{ recordingId: EntityId }>) => {
            state.recordingsWaiting.push(action.payload.recordingId);
            state.recordingsReadyForPlayback = state.recordingsReadyForPlayback.filter((x) => x !== action.payload.recordingId);
        },

        // debug
        toggleDebugShowStatistics: (state) => {
            state.debugShowStatistics = !state.debugShowStatistics;
        },
        toggleDebugShowBufferingRecordings: (state) => {
            state.debugShowBufferingRecordings = !state.debugShowBufferingRecordings;
        },
        toggleDebugShowRecordingsOutsidePlaybackAndBufferingWindow: (state) => {
            state.debugShowRecordingsOutsidePlaybackAndBufferingWindow = !state.debugShowRecordingsOutsidePlaybackAndBufferingWindow;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchRecordingsForDeviceAsync.fulfilled, (state, action) => {
            recordingAdapter.addMany(state.recordings, action.payload);
        });
        builder.addCase(removeRecordingsAndStateForDeviceAsync.fulfilled, (state, action) => {
            // remove recordings
            recordingAdapter.removeMany(state.recordings, action.payload);

            // remove recording state
            state.recordingsReadyForPlayback = state.recordingsReadyForPlayback.filter((x) => !action.payload.includes(x));

            state.recordingsWaiting = state.recordingsWaiting.filter((x) => !action.payload.includes(x));

            state.recordingsFailedToLoad = state.recordingsFailedToLoad.filter((x) => !action.payload.includes(x));
        });
    },
});

// exports
export const playbackThunks = {
    fetchRecordingsForDeviceAsync,
    removeRecordingsAndStateForDeviceAsync,
};
export const playbackActions = playbackSlice.actions;

export default playbackSlice.reducer;
