import { useRef, useEffect } from "react";

import { useAppSelector } from "app/hooks";
import { selectApplicationInsights } from "features/config/store/selectors";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

export const useApplicationInsights = () => {
    const appInsightsConfiguration = useAppSelector(selectApplicationInsights);

    const reactPlugin = useRef<ReactPlugin>(new ReactPlugin());
    const applicationInsightsInstance = useRef<ApplicationInsights | null>();

    useEffect(() => {
        if (appInsightsConfiguration?.connectionString && !applicationInsightsInstance.current) {
            const browserHistory = createBrowserHistory();

            applicationInsightsInstance.current = new ApplicationInsights({
                config: {
                    connectionString: appInsightsConfiguration.connectionString,
                    disableInstrumentationKeyValidation: true, // we use a dummy value which is replaced server-side
                    extensions: [reactPlugin.current],
                    extensionConfig: {
                        [reactPlugin.current.identifier]: { history: browserHistory },
                        ["AppInsightsCfgSyncPlugin"]: {
                            cfgUrl: "",
                        },
                    },
                },
            });

            if (appInsightsConfiguration.ignoreEvents && appInsightsConfiguration.ignoreEvents.length > 0) {
                applicationInsightsInstance.current.addDependencyInitializer((details) => {
                    if (details.item.target) {
                        const lowerCaseTarget = details.item.target.toLowerCase();

                        const ignoreRuleMatched = appInsightsConfiguration.ignoreEvents
                            // apply rules based on success
                            .filter((x) => (details.item.success ? x.ignoreSuccessfulEvents : x.ignoreFailedEvents))
                            // any match on target?
                            .some((x) => lowerCaseTarget.indexOf(x.targetStartsWith) > -1);

                        if (ignoreRuleMatched) {
                            // Stop event from being reported
                            return false;
                        }
                    }
                });
            }

            applicationInsightsInstance.current.loadAppInsights();
        }
    }, [appInsightsConfiguration]);

    return {
        reactPlugin: reactPlugin.current,
    };
};
