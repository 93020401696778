import { PropsWithChildren } from "react";

import { useAppSelector } from "app/hooks";
import { MatchType } from "features/appFeatures/types/matchType";
import { selectHasSuccessfullyFetchedFeatures, selectUserHasFeatures } from "features/appFeatures/store/selectors";
import { AppFeature } from "./types/AppFeature";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { styled, Toolbar } from "@mui/material";
import { MsalInstanceService } from "features/auth/MsalInstanceService";
import { GlobalErrorMessageProps, GlobalErrorMessage } from "features/core/GlobalErrorMessage";

const Main = styled("main")(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
}));

export interface FeatureFlagGuardProps {
    accessibleWithFeatureFlags: AppFeature[];
    withAuthentication?: boolean;
    unauthorizedTitle?: string;
    matchType?: MatchType;
}

export function FeatureFlagGuard(props: PropsWithChildren<FeatureFlagGuardProps>) {
    const hasSuccessfullyFetchedFeatures = useAppSelector(selectHasSuccessfullyFetchedFeatures);

    const userHasAllRequiredFeatures = useAppSelector((state) => selectUserHasFeatures(state, props.accessibleWithFeatureFlags, props.matchType ?? MatchType.All));

    const userHasAccess = hasSuccessfullyFetchedFeatures && userHasAllRequiredFeatures;

    const currentUserName = MsalInstanceService.getInstance().getCurrentUserName();

    const alertProps: GlobalErrorMessageProps = !hasSuccessfullyFetchedFeatures
        ? {
              title: "Unable to fetch user permissions",
              message: "There was an error while trying to fetch user permissions. Please contact the service desk.",
              alertSeverity: "error",
              debugData: undefined,
          }
        : {
              title: props.unauthorizedTitle ? props.unauthorizedTitle : "Unauthorized",
              message: `Please contact the service desk to enable the appropriate feature(s) in iCOPP for your account (you are currently logged in as: ${currentUserName}).`,
              alertSeverity: "warning",
              debugData: {
                  requestedFeatureFlags: props.accessibleWithFeatureFlags,
                  matchType: props.matchType,
              },
          };

    return (
        <>
            {props.withAuthentication && (
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                    {userHasAccess && props.children}
                    {!userHasAccess && (
                        <Main>
                            <Toolbar />
                            <GlobalErrorMessage {...alertProps} />
                        </Main>
                    )}
                </MsalAuthenticationTemplate>
            )}
            {!props.withAuthentication && userHasAccess && props.children}
        </>
    );
}
