import { EntityId, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { sitesAdapter } from "./state";
import { dataApi } from "services/streamingApi/data.api";

// thunks
const fetchSiteAsync = createAsyncThunk("sites/fetchSiteAsync", async (siteId: EntityId, thunkApi) => {
    // fetch devices for specified site
    const { data, error, isSuccess } = await thunkApi.dispatch(dataApi.endpoints.getSiteById.initiate(siteId));

    if (isSuccess) {
        return data;
    }

    throw new Error(`Error while retrieving site data: ${error}`);
});

// slice
export const sitesSlice = createSlice({
    name: "sites",
    initialState: sitesAdapter.getInitialState,
    reducers: {
        selectSite: sitesAdapter.addOne,
        deselectSite: sitesAdapter.removeOne,
    },
    extraReducers(builder) {
        builder.addCase(fetchSiteAsync.fulfilled, (state, action) => {
            sitesAdapter.addOne(state, action.payload);
        });
    },
});

// exports
export const sitesThunks = { fetchSiteAsync };
export const sitesActions = sitesSlice.actions;

export default sitesSlice.reducer;
