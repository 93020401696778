import dayjs from "dayjs";

import { Recording } from "types/Recording";

export const getRecordingWithPlaybackState = (bufferStartDateTimeTicks: number, bufferEndDateTimeTicks: number, currentPlaybackTimeStamp: number, recording: Recording) => {
    const startTimeTicks = dayjs(recording.startTime).valueOf();
    const stopTimeTicks = dayjs(recording.stopTime).valueOf();

    const inBufferWindow = startTimeTicks <= bufferEndDateTimeTicks && stopTimeTicks >= bufferStartDateTimeTicks;

    const inPlaybackWindow = startTimeTicks <= currentPlaybackTimeStamp && stopTimeTicks >= currentPlaybackTimeStamp;

    return {
        ...recording,
        inBufferWindow: inBufferWindow && !inPlaybackWindow,
        inPlaybackWindow,
    };
};
