import type { AppStartListening } from "types/AppStartListening";
import { coreActions } from "./slice";
import { selectLastWindowModeYOffset } from "./selectors";

export const addCoreListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: coreActions.setUIMode,
        effect: async (action, listenerApi) => {
            if (!document.fullscreenEnabled) return;

            if (action.payload === "fullscreen") {
                // remember the current y scroll position
                listenerApi.dispatch(coreActions.setLastWindowModeYOffset(window.scrollY));
                await document.body.requestFullscreen();
            } else if (action.payload === "window") {
                if (document.fullscreenElement) {
                    await document.exitFullscreen();
                }

                const lastWindowModeYOffset = selectLastWindowModeYOffset(listenerApi.getState());
                window.scrollTo(0, lastWindowModeYOffset);
            }
        },
    });
};
