import { EntityId } from "@reduxjs/toolkit";
import { streamingApi } from "./base.api";
import { Recording } from "types/Recording";

const BASE_PATH = "/recordings";

export const recordingsApi = streamingApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeviceRecordings: builder.query<Array<Recording>, { deviceId: EntityId; startTime: string; stopTime: string }>({
            query: (arg) => {
                const { startTime, stopTime } = arg;

                return {
                    url: `${BASE_PATH}/devices/${arg.deviceId}/recordings`,
                    params: { startTime, stopTime },
                };
            },
            transformResponse: (response: Array<Recording>, meta, arg) => {
                // save id with response object
                response.forEach((x) => {
                    x.id = x.recordingId;
                    x.deviceId = arg.deviceId;
                });

                return response;
            },
        }),
        retrieveRelativeDownloadUrl: builder.mutation<
            string,
            {
                deviceId: EntityId;
                recordingId: EntityId;
                startTime: string;
                stopTime: string;
            }
        >({
            query: (arg) => ({
                url: `${BASE_PATH}/devices/${arg.deviceId}/recordings/temporarydownloadtoken`,
                method: "GET",
            }),
            transformResponse: (response: string, meta, arg) => {
                return `${BASE_PATH}/download/${arg.deviceId}/${arg.recordingId}/${response}?startTime=${arg.startTime}&stopTime=${arg.stopTime}`;
            },
        }),
    }),
    overrideExisting: true,
});

export const { useRetrieveRelativeDownloadUrlMutation } = recordingsApi;
