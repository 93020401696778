import { useEffect } from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";

import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { useAppSelector } from "app/hooks";

import { selectEnvironmentConfig } from "features/config/store/selectors";

export default function PreferredEnvironmentPrompt() {
    const environmentConfig = useAppSelector(selectEnvironmentConfig);

    useEffect(() => {
        const preferredEnvironmentUrl = environmentConfig?.preferredEnvironmentUrl;

        if (!preferredEnvironmentUrl || preferredEnvironmentUrl.length === 0) {
            return;
        }

        const action = (snackbarId: SnackbarKey) => (
            <Stack direction="row" spacing={1}>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                        window.location.href = preferredEnvironmentUrl;
                    }}
                >
                    Go
                </Button>
                <Button
                    variant="outlined"
                    color="info"
                    onClick={() => {
                        closeSnackbar(snackbarId);
                    }}
                >
                    Dismiss
                </Button>
            </Stack>
        );

        enqueueSnackbar(
            <Typography variant="body2">
                Currently you're working on the <Typography component={"span"} color={"warning"} variant="inherit" textTransform={"uppercase"} fontWeight={"bold"}>
                    {environmentConfig.name}
                </Typography> environment
                <br />
                You can dismiss this message or go to the preferred environment
            </Typography>,
            { action, variant: "info", persist: true },
        );
    }, [environmentConfig]);

    return <></>;
}
