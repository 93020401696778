import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { createBaseQueryFnWithBaseUrlFromStore } from "features/core/baseUrlFromStoreQuery";
import { selectStreamingApiBaseUrl, selectStreamingApiScopes } from "features/config/store/selectors";
import { MsalInstanceService } from "features/auth/MsalInstanceService";

const configuredBaseQuery = createBaseQueryFnWithBaseUrlFromStore(
    fetchBaseQuery({
        baseUrl: undefined,
        prepareHeaders: async (headers, api) => {
            headers.set("Accept", "application/json");
            headers.set("Content-Type", "application/json");

            const msalInstanceService = MsalInstanceService.getInstance();
            const streamingApiScopes = selectStreamingApiScopes(api.getState() as RootState);

            const accessToken = await msalInstanceService.acquireTokenAsync(streamingApiScopes);

            if (accessToken) {
                headers.set("authorization", `Bearer ${accessToken}`);
            }

            return headers;
        },
    }),
    selectStreamingApiBaseUrl,
);

export const streamingApi = createApi({
    reducerPath: "streamingApi",
    baseQuery: configuredBaseQuery,
    endpoints: () => ({}),
    tagTypes: ["SiteMute", "PortState", "DownloadJobs"],
    invalidationBehavior: "immediately",
});
