import { useGetMyEnabledFeaturesQuery } from "services/streamingApi/user.api";

export interface AppFeatureLoaderProps {
    children?: React.ReactNode;
    refreshFeatureIntervalInMs?: number;
}

const AppFeatureLoader = (props: AppFeatureLoaderProps) => {
    const { isLoading } = useGetMyEnabledFeaturesQuery(undefined, {
        pollingInterval: props.refreshFeatureIntervalInMs ?? 0,
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return props.children;
};

export { AppFeatureLoader };
