import { isAnyOf } from "@reduxjs/toolkit";

import { RootState } from "app/store";
import { playbackActions, playbackThunks } from "./slice";
import { sitesActions } from "features/sites/store/slice";
import { playbackSessionSelectors } from "./selectors.session";

import type { AppStartListening } from "types/AppStartListening";
import { liveStreamingActions } from "features/liveStreaming/store/slice";

// listeners
export const addPlaybackListeners = (startListening: AppStartListening) => {
    // when a user selects a site using search field in header for example
    startListening({
        actionCreator: sitesActions.selectSite,
        effect: async (action, listenerApi) => {
            // set the site as active in the slice state
            listenerApi.dispatch(
                playbackActions.setSiteId({
                    siteId: action.payload.id,
                }),
            );
        },
    });
    startListening({
        actionCreator: liveStreamingActions.setSiteAsActive,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(playbackActions.resetState());
            // set the site as active in the slice state
            listenerApi.dispatch(
                playbackActions.setSiteId({
                    siteId: action.payload.siteId,
                }),
            );
        },
    });
    startListening({
        actionCreator: sitesActions.deselectSite,
        effect: async (action, listenerApi) => {
            const state = listenerApi.getState() as RootState;
            // if the site being deselected is the active site, reset the siteId in the slice state
            if (state.playback.siteId === action.payload) {
                listenerApi.dispatch(playbackActions.resetState());
            }
        },
    });

    // when date time range changes stop current playback
    startListening({
        actionCreator: playbackActions.setStartAndEndDateTime,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(playbackActions.stopPlayback());
        },
    });

    // when date time range or playback position changes manually, reset playback loading state
    startListening({
        matcher: isAnyOf(playbackActions.setStartAndEndDateTime, playbackActions.setPlaybackStartDateTime, playbackActions.stopPlayback, playbackActions.addSecondsToPlaybackStartDateTime, playbackActions.subtractSecondsFromPlaybackStartDateTime),
        effect: async (action, listenerApi) => {
            const state = listenerApi.getState() as RootState;

            const continuousPlaybackWindow = playbackSessionSelectors.selectContinuousPlaybackWindow(state);

            if (!continuousPlaybackWindow.hasContinuousPlaybackWindow) {
                listenerApi.dispatch(playbackActions.resetRecordingsPlaybackState());
            }
        },
    });

    // when device is deselected remove all recordings and recording state
    startListening({
        actionCreator: playbackActions.upsertDeviceSelectionState,
        effect: async (action, listenerApi) => {
            if (!action.payload.selected) {
                listenerApi.dispatch(
                    playbackThunks.removeRecordingsAndStateForDeviceAsync({
                        deviceId: action.payload.deviceId,
                    }),
                );
            }
        },
    });
};
