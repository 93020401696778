// https://learn.microsoft.com/en-us/microsoft-edge/web-platform/user-agent-guidance#user-agent-client-hints-javascript-api
/// <reference types="user-agent-data-types" />

import { useEffect } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography/Typography";

import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { useAppSelector } from "app/hooks";

import { selectUnsupportedBrowsersConfig } from "features/config/store/selectors";

export default function UnsupportedBrowserPrompt() {
    const unsupportedBrowsersConfig = useAppSelector(selectUnsupportedBrowsersConfig);

    useEffect(() => {
        const browserBrands = navigator.userAgentData?.brands;

        if (!unsupportedBrowsersConfig || unsupportedBrowsersConfig.browsers.length === 0 || !browserBrands || browserBrands.length === 0) {
            return;
        }

        const unsupportedBrowser = browserBrands.find((browserBrand) => {
            const found = unsupportedBrowsersConfig.browsers.some((unsupportedBrowser) => {
                if (unsupportedBrowser.brand.toLowerCase() === browserBrand.brand.toLowerCase()) {
                    const brandVersionNumber = Number.parseInt(browserBrand.version);

                    if (!unsupportedBrowser.upToAndIncludingVersion || (!Number.isNaN(brandVersionNumber) && unsupportedBrowser.upToAndIncludingVersion >= brandVersionNumber)) {
                        return true;
                    }
                }

                return false;
            });

            if (found) {
                return browserBrand;
            }
        });

        if (unsupportedBrowser) {
            const action = (snackbarId: SnackbarKey) => (
                <Button
                    variant="outlined"
                    color="info"
                    onClick={() => {
                        closeSnackbar(snackbarId);
                    }}
                >
                    Dismiss
                </Button>
            );

            enqueueSnackbar(
                <Typography variant="body2">
                    Unsupported browser <Typography component={"span"} color={"warning"} variant="inherit" textTransform={"uppercase"} fontWeight={"bold"}>
                        {unsupportedBrowser.brand} ({unsupportedBrowser.version})
                    </Typography> detected
                    <br />
                    Please use our recommended browser <Typography component={"span"} color={"warning"} variant="inherit" textTransform={"uppercase"} fontWeight={"bold"}>
                        {unsupportedBrowsersConfig.preferredBrowser}
                    </Typography>
                </Typography>,
                { action, variant: "error", persist: true },
            );
        }
    }, [unsupportedBrowsersConfig]);

    return <></>;
}
