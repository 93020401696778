import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "types/AppConfig";

export const configApi = createApi({
    reducerPath: "configApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
    }),
    endpoints: (builder) => ({
        getConfig: builder.query<AppConfig, void>({
            query: () => import.meta.env.VITE_CONFIG_URL,
        }),
    }),
});

export const { useGetConfigQuery } = configApi;
