import { createAsyncThunk, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "app/store";
import { sitesActions, sitesThunks } from "features/sites/store/slice";
import { devicesThunks } from "features/devices/store/slice";
import { selectPagedDevicesBySiteId } from "features/devices/store/selectors";
import { coreActions } from "features/core/store/slice";
import { initialState, SiteDeviceSelection } from "./state";
import { getSiteDeviceSelectionBySiteId } from "./helpers";
import { selectAllSites } from "features/sites/store/selectors";
import { StreamQualityType } from "types/StreamQualityType";
import dayjs from "dayjs";

// thunks
const initializeSiteAsync = createAsyncThunk(
    "liveStreaming/initializeSiteAsync",
    async (
        payload: {
            siteId: EntityId;
            pageSize: number;
        },
        thunkApi,
    ) => {
        // fetch devices for site (cached)
        await thunkApi.dispatch(devicesThunks.fetchDevicesForSiteAsync(payload.siteId));

        // select devices based on pagination
        const state = thunkApi.getState() as RootState;
        const pageNumber = 1;

        const deviceIdsSelectedByDefault = selectPagedDevicesBySiteId(state, payload.siteId, pageNumber, payload.pageSize).map((device) => device.id as EntityId);

        return {
            siteId: payload.siteId,
            deviceIds: deviceIdsSelectedByDefault,
        };
    },
);

const initializeSiteOnDeepLinkAsync = createAsyncThunk("liveStreaming/initializeSiteOnDeepLinkAsync", async (siteDeviceSelections: SiteDeviceSelection[], thunkApi) => {
    thunkApi.dispatch(
        liveStreamingActions.setSiteDeviceSelectionsCollection({
            siteDeviceSelections: siteDeviceSelections,
        }),
    );

    // for each site fetch the site and the devices
    siteDeviceSelections.forEach(async (siteDeviceSelection) => {
        // fetch site
        thunkApi.dispatch(sitesThunks.fetchSiteAsync(siteDeviceSelection.siteId));

        // fetch devices for site (cached)
        thunkApi.dispatch(devicesThunks.fetchDevicesForSiteAsync(siteDeviceSelection.siteId));
    });
});

const closeSiteAsync = createAsyncThunk("liveStreaming/closeSiteAsync", async (siteId: EntityId, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    // remove site from state
    thunkApi.dispatch(sitesActions.deselectSite(siteId.toString()));

    // update liveStream state
    const payload = {
        siteId,
    };

    thunkApi.dispatch(liveStreamingActions.removeSiteFromSelection(payload));

    // if site is currently displayed in fullscreen, exit fullscreen mode
    if (state.liveStreaming.fullscreenDeviceId) {
        const siteDeviceSelection = getSiteDeviceSelectionBySiteId(state.liveStreaming, siteId);

        if (siteDeviceSelection?.selectedDevices?.some((item) => item.deviceId === state.liveStreaming.fullscreenDeviceId)) {
            // exit fullscreen mode
            thunkApi.dispatch(coreActions.setUIMode("window"));
        }
    }

    thunkApi.dispatch(setFirstAvailableSiteAsActiveAsync());
});

const setFirstAvailableSiteAsActiveAsync = createAsyncThunk("liveStreaming/setFirstAvailableSiteAsActiveAsync", async (_: void, { getState, dispatch }) => {
    const state = getState() as RootState;

    const allSites = selectAllSites(state);

    let siteId: EntityId | undefined;

    if (state.liveStreaming.siteDeviceSelections && state.liveStreaming.siteDeviceSelections.length > 0) {
        // live streaming has site with selected devices objects, use the first one as selected site
        siteId = state.liveStreaming.siteDeviceSelections[0].siteId;
    } else if (allSites.length > 0) {
        // live streaming has never created a selected devices objects, user probably never used the search box
        siteId = allSites[0].id;
    }

    if (siteId) {
        dispatch(
            liveStreamingActions.setSiteAsActive({
                siteId,
            }),
        );
    }
});

const setSelectedDevicesForSiteByPagingAsync = createAsyncThunk(
    "liveStreaming/setSelectedDevicesForSiteByPagingAsync",
    async (
        payload: {
            siteId: EntityId;
            pageSize: number;
            pageNumber: number;
        },
        thunkApi,
    ) => {
        const state = thunkApi.getState() as RootState;

        const selectPagedDeviceIdsBySite = selectPagedDevicesBySiteId(state, payload.siteId, payload.pageNumber, payload.pageSize).map((d) => d.id as EntityId);

        // First set the current site as active
        thunkApi.dispatch(
            liveStreamingActions.setSiteAsActive({
                siteId: payload.siteId,
            }),
        );

        // Update the selected devices
        thunkApi.dispatch(
            liveStreamingActions.setSelectedDevicesForSite({
                siteId: payload.siteId,
                deviceIds: selectPagedDeviceIdsBySite,
                deviceSelectionIsDoneByPaging: true,
                pageNumber: payload.pageNumber,
            }),
        );
    },
);

// slice
export const liveStreamingSlice = createSlice({
    name: "liveStreaming",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSiteDeviceSelectionsCollection: (
            state,
            action: PayloadAction<{
                siteDeviceSelections: SiteDeviceSelection[];
            }>,
        ) => {
            state.siteDeviceSelections = Object.assign([], action.payload.siteDeviceSelections);
        },
        setSiteAsActive: (
            state,
            action: PayloadAction<{
                siteId: EntityId;
            }>,
        ) => {
            state.activeSiteId = action.payload.siteId;

            // check if state contains siteDeviceSelections object for specified site
            if (!getSiteDeviceSelectionBySiteId(state, action.payload.siteId)) {
                // need to create new state
                if (!state.siteDeviceSelections) {
                    state.siteDeviceSelections = [];
                }

                state.siteDeviceSelections.unshift({
                    siteId: action.payload.siteId,
                    selectedDevices: undefined,
                    deviceSelectionIsDoneByPaging: true,
                    pageNumber: 1,
                    devicesWithBadConnection: [],
                });
            }

            // set fullscreen device to first selected device
            const siteDeviceSelection = getSiteDeviceSelectionBySiteId(state, action.payload.siteId);
            state.fullscreenDeviceId = siteDeviceSelection?.selectedDevices?.at(0)?.deviceId;
        },
        removeSiteFromSelection: (
            state,
            action: PayloadAction<{
                siteId: EntityId;
            }>,
        ) => {
            if (!state.siteDeviceSelections) {
                return;
            }

            const isCurrentActiveSite = state.activeSiteId === action.payload.siteId;
            state.siteDeviceSelections = state.siteDeviceSelections.filter((x) => x.siteId !== action.payload.siteId);

            if (isCurrentActiveSite) {
                state.activeSiteId = undefined;
            }
        },
        // called when selecting and deselecting
        setDeviceSelectionState: (
            state,
            action: PayloadAction<{
                siteId: EntityId;
                deviceId: EntityId;
                selected: boolean;
            }>,
        ) => {
            if (state.activeSiteId) {
                const siteDeviceSelection = getSiteDeviceSelectionBySiteId(state, action.payload.siteId);

                if (siteDeviceSelection && action.payload.selected) {
                    // add to (begin of) selection
                    siteDeviceSelection.selectedDevices?.unshift({
                        deviceId: action.payload.deviceId,
                        properties: { streamQuality: null },
                    });
                    siteDeviceSelection.deviceSelectionIsDoneByPaging = false;
                } else if (siteDeviceSelection && !action.payload.selected) {
                    // remove from selection
                    siteDeviceSelection.selectedDevices = siteDeviceSelection.selectedDevices?.filter((x) => x.deviceId !== action.payload.deviceId);
                    siteDeviceSelection.deviceSelectionIsDoneByPaging = false;
                }
            }
        },
        setSelectedDevicesForSite: (
            state,
            action: PayloadAction<{
                siteId: EntityId;
                deviceIds: EntityId[];
                deviceSelectionIsDoneByPaging: boolean;
                pageNumber: number;
            }>,
        ) => {
            const siteDeviceSelection = getSiteDeviceSelectionBySiteId(state, action.payload.siteId);

            if (siteDeviceSelection) {
                siteDeviceSelection.selectedDevices = action.payload.deviceIds.map((deviceId) => ({
                    deviceId,
                    properties: { streamQuality: null },
                }));

                // Mark if the new device selectionstate is set using the paging component
                siteDeviceSelection.deviceSelectionIsDoneByPaging = action.payload.deviceSelectionIsDoneByPaging;
                siteDeviceSelection.pageNumber = action.payload.pageNumber;
            }
        },
        // fullscreen functionality
        setFullscreenDeviceId: (
            state,
            action: PayloadAction<{
                siteId: EntityId;
                deviceId: EntityId;
            }>,
        ) => {
            state.fullscreenDeviceId = action.payload.deviceId;

            // set site of fullscreen device to active
            state.activeSiteId = action.payload.siteId;
        },
        setPreviousFullscreenDeviceId: (state) => {
            const siteSelection = getSiteDeviceSelectionBySiteId(state, state.activeSiteId || -1);

            if (!state.fullscreenDeviceId || !siteSelection || !siteSelection.selectedDevices) return;

            const nextIndex = siteSelection.selectedDevices.findIndex((item) => item.deviceId === state.fullscreenDeviceId) - 1;

            state.fullscreenDeviceId = siteSelection.selectedDevices.at(nextIndex)?.deviceId;
        },
        setNextFullscreenDeviceId: (state) => {
            const siteSelection = getSiteDeviceSelectionBySiteId(state, state.activeSiteId || -1);

            if (!state.fullscreenDeviceId || !siteSelection || !siteSelection.selectedDevices) return;

            let nextIndex = siteSelection.selectedDevices.findIndex((item) => item.deviceId === state.fullscreenDeviceId) + 1;

            if (nextIndex > siteSelection.selectedDevices.length - 1) {
                nextIndex = 0;
            }

            state.fullscreenDeviceId = siteSelection.selectedDevices[nextIndex]?.deviceId;
        },
        setSelectedDeviceStreamQuality: (
            state,
            action: PayloadAction<{
                deviceId: EntityId;
                streamQuality: StreamQualityType;
            }>,
        ) => {
            if (!state.siteDeviceSelections) {
                return;
            }

            const device = state.siteDeviceSelections.flatMap((x) => x.selectedDevices).find((x) => x?.deviceId === action.payload.deviceId);

            if (device) {
                device.properties.streamQuality = action.payload.streamQuality;
            }
        },
        setBadInternetConnectionDetected: (state, action: PayloadAction<{ deviceId: EntityId }>) => {
            const siteDeviceSelection = getSiteDeviceSelectionBySiteId(state, state.activeSiteId || -1);

            if (!siteDeviceSelection) return;

            const deviceWithBadConnection = siteDeviceSelection.devicesWithBadConnection.find((d) => d.deviceId === action.payload.deviceId);
            if (!deviceWithBadConnection) {
                siteDeviceSelection.devicesWithBadConnection.push({
                    deviceId: action.payload.deviceId,
                    badNetworkDetectedTime: dayjs().toISOString(),
                });
            } else {
                deviceWithBadConnection.badNetworkDetectedTime = dayjs().toISOString();
            }
        },
    },
    extraReducers(builder) {
        builder.addCase(initializeSiteAsync.fulfilled, (state, action) => {
            const siteDeviceSelection = getSiteDeviceSelectionBySiteId(state, action.payload.siteId);

            if (siteDeviceSelection && !siteDeviceSelection.selectedDevices) {
                siteDeviceSelection.selectedDevices = action.payload.deviceIds.map((deviceId) => ({
                    deviceId,
                    properties: { streamQuality: null },
                }));
            }
        });
    },
});

// exports
export const liveStreamingThunks = {
    initializeSiteOnDeepLinkAsync,
    initializeSiteAsync,
    closeSiteAsync,
    setFirstAvailableSiteAsActiveAsync,
    setSelectedDevicesForSiteByPagingAsync,
};
export const liveStreamingActions = liveStreamingSlice.actions;

export default liveStreamingSlice.reducer;
