export enum AppFeature {
    FrontEndAccess = "FrontEnd.Access",
    FrontEndUnsupportedBrowserPrompt = "FrontEnd.UnsupportedBrowserPrompt",

    CameraAccess = "DeviceAccess.Camera",
    IntercomAccess = "DeviceAccess.Intercom",

    LiveStreaming = "LiveStreaming",
    LiveStreamingSiteDetails = "LiveStreaming.SiteDetails",
    LiveStreamingSensitiveSiteDetails = "LiveStreaming.SensitiveSiteDetails",

    Playback = "Playback",
    PlaybackSingleDownload = "Playback.SingleDownload",
    PlaybackBatchDownload = "Playback.BatchDownload",

    SiteOperationsDingDong = "SiteOperations.DingDong",
    SiteOperationsMuteAudio = "SiteOperations.MuteAudio",

    SiteOperationsDoors = "SiteOperations.Doors",
    SiteOperationsEmergency = "SiteOperations.Emergency",
    SiteOperationsClubLights = "SiteOperations.ClubLights",
    SiteOperationsAssist = "SiteOperations.Assist",

    AdminDebugTools = "Admin.DebugTools",
}
