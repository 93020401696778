import type { AppStartListening } from "types/AppStartListening";

import { dataApi } from "services/streamingApi/data.api";

import { sitesActions } from "features/sites/store/slice";

export const addDevicesListeners = (startListening: AppStartListening) => {
    // preload devices on site selection
    startListening({
        actionCreator: sitesActions.selectSite,
        effect: async (action, listenerApi) => {
            const payload = {
                siteId: action.payload.id,
                category: "video", // todo should we make this configurable or something?
            };

            listenerApi.dispatch(dataApi.util.prefetch("getDevicesBySiteAndCategory", payload, {}));
        },
    });
};
