import { useEffect, useState } from "react";

import { useIdleTimerContext } from "react-idle-timer";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function AutoCloseDialog() {
    const { getRemainingTime, activate, isPrompted } = useIdleTimerContext();

    const [remaining, setRemaining] = useState<number>(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });

    const handleStillHere = () => {
        activate();
    };

    return (
        <>
            <Dialog open={isPrompted()}>
                <DialogTitle>Current tab is about to close</DialogTitle>
                <DialogContent>Closing this tab in {remaining} seconds</DialogContent>
                <DialogActions>
                    <Button onClick={handleStillHere}>Keep alive</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
