import { SignalRHub } from "../types/SignalRHub";

export enum LiveStreamingHubMethodNames {
    SubscribeToDeviceLiveStreamingUpdates = "SubscribeToDeviceLiveStreamingUpdates",
    UnsubscribeFromDeviceLiveStreamingUpdates = "UnsubscribeFromDeviceLiveStreamingUpdates",
}

export enum LiveStreamingClientMethodNames {
    DeviceLiveStreamUpdated = "DeviceLiveStreamUpdated",
}

export class LiveStreamingHub implements SignalRHub {
    hubName: string = "LiveStreaming";
    hubMethodNames = [LiveStreamingHubMethodNames.SubscribeToDeviceLiveStreamingUpdates, LiveStreamingHubMethodNames.UnsubscribeFromDeviceLiveStreamingUpdates];
    clientMethodNames = [LiveStreamingClientMethodNames.DeviceLiveStreamUpdated];
}
