import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";

import { UserPlayMode } from "../types/UserPlayMode";

import { Recording } from "types/Recording";

export const recordingAdapter = createEntityAdapter<Recording>({
    sortComparer: (a, b) => a.startTime.localeCompare(b.startTime),
});

export interface PlaybackState {
    // selection
    siteId?: EntityId;
    deviceIds: EntityId[];

    startDateTime?: string;
    endDateTime?: string;

    // session
    sessionId?: string;

    recordings: EntityState<Recording, EntityId>;

    userRequestedPlayMode: UserPlayMode;

    playbackStartDateTime?: string;
    playbackElapsedTimeInMs: number;
    continuousPlaybackStartDateTime?: string;
    continuousPlaybackEndDateTime?: string;
    playbackStartDateTimeChangedTimestamp?: number;

    bufferWindowInSeconds: number;

    recordingsReadyForPlayback: EntityId[];
    recordingsFailedToLoad: EntityId[];
    recordingsWaiting: EntityId[];

    // debug
    debugShowStatistics: boolean;
    debugShowBufferingRecordings: boolean;
    debugShowRecordingsOutsidePlaybackAndBufferingWindow: boolean;
}

export const initialState: PlaybackState = {
    deviceIds: [],

    recordings: recordingAdapter.getInitialState(),

    userRequestedPlayMode: "stop",

    playbackElapsedTimeInMs: 0,

    bufferWindowInSeconds: 30,

    recordingsReadyForPlayback: [],
    recordingsFailedToLoad: [],
    recordingsWaiting: [],

    debugShowStatistics: false,
    debugShowBufferingRecordings: false,
    debugShowRecordingsOutsidePlaybackAndBufferingWindow: false,
};
