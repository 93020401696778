import { EntityId } from "@reduxjs/toolkit";
import { SelectedDeviceProperties } from "features/liveStreaming/types/SelectedDeviceProperties";

export interface SelectedDeviceDevice {
    deviceId: EntityId;
    properties: SelectedDeviceProperties;
}

export interface DeviceWithBadConnection {
    deviceId: EntityId;
    badNetworkDetectedTime: string;
}

export interface SiteDeviceSelection {
    siteId: EntityId;
    /**
     *  Ajv schema default value if not present
     *  @default false
     */
    deviceSelectionIsDoneByPaging: boolean;
    pageNumber: number;
    selectedDevices?: SelectedDeviceDevice[];
    /**
     *  Ajv schema default value if not present
     *  @default []
     */
    devicesWithBadConnection: DeviceWithBadConnection[];
}

export interface LiveStreamingState {
    activeSiteId?: EntityId;
    siteDeviceSelections?: SiteDeviceSelectionsList;
    fullscreenDeviceId?: EntityId;
}

export const initialState: LiveStreamingState = {
    activeSiteId: undefined,
    siteDeviceSelections: undefined,
    fullscreenDeviceId: undefined,
};

export type SiteDeviceSelectionsList = Array<SiteDeviceSelection>;
