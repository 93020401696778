import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { AppFeature } from "../types/AppFeature";

export interface AppFeaturesState {
    hasSuccessfullyFetchedFeatures: boolean;
    enabledFeaturesErrorResponse: FetchBaseQueryError | undefined;
    enabledFeatures: AppFeature[];
}

export const initialState: AppFeaturesState = {
    hasSuccessfullyFetchedFeatures: false,
    enabledFeaturesErrorResponse: undefined,
    enabledFeatures: [],
};
