import { EntityId, createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Device } from "types/Device";
import { devicesAdapter } from "./state";

export const { selectAll: selectAllDevices, selectById: selectDeviceById } = devicesAdapter.getSelectors((state: RootState) => state.devices);

export const selectDevicesBySiteId = createSelector([selectAllDevices, (state: RootState, siteId: EntityId) => siteId], (devices: Device[], siteId: EntityId) => devices.filter((x) => x.siteId === siteId));

export const selectDevicesBySiteIdSortedByName = createSelector(
    [selectDevicesBySiteId, (state: RootState, siteId: EntityId) => siteId],
    (
        devices: Device[],
        //, siteId: EntityId
    ) => devices.slice().sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectPagedDevicesBySiteId = createSelector(
    [
        selectDevicesBySiteId,
        (
            state: RootState,
            siteId: EntityId,
            pageNumber: number,
            // pageSize: number,
        ) => pageNumber,
        (state: RootState, siteId: EntityId, pageNumber: number, pageSize: number) => pageSize,
    ],
    (devices: Device[], pageNumber: number, pageSize: number) => {
        return devices.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    },
);

export const selectDeviceByIdFactory = () => createSelector([selectDeviceById], (device: Device | undefined) => device);
