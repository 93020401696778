import { useEffect } from "react";

import { useAppSelector } from "app/hooks";
import { useMatches } from "react-router-dom";
import { RouteHandleDocumentTitleExtraInfo } from "types/RouteHandleDocumentTitleExtraInfo";

export default function AppTitle() {
    const activeRoutes = useMatches();

    const deepestRoute = activeRoutes.findLast((match) => (match.handle as RouteHandleDocumentTitleExtraInfo)?.getDocumentTitleExtraInfoSelector !== undefined);

    const documentTitleExtraInfoSelector = deepestRoute ? (deepestRoute.handle as RouteHandleDocumentTitleExtraInfo).getDocumentTitleExtraInfoSelector() : () => "";

    const documentTitleExtraInfo = useAppSelector(documentTitleExtraInfoSelector);

    useEffect(() => {
        if (documentTitleExtraInfo) {
            document.title = `${documentTitleExtraInfo} - iCOPP`;
        } else {
            document.title = "iCOPP";
        }
    }, [documentTitleExtraInfo]);

    return <></>;
}
