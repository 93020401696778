import { useCallback } from "react";

import Alert, { AlertColor, AlertPropsColorOverrides } from "@mui/material/Alert";
import { OverridableStringUnion } from "@mui/types";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import { ContentCopy } from "@mui/icons-material";
import Typography from "@mui/material/Typography";

import { useAppSelector } from "app/hooks";

import { selectFeaturesDebugData } from "features/appFeatures/store/selectors";

export interface GlobalErrorMessageProps {
    title: string;
    message: string;
    alertSeverity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
    debugData?: object;
}

export function GlobalErrorMessage(props: GlobalErrorMessageProps) {
    const featuresDebugData = useAppSelector((state) => selectFeaturesDebugData(state, props.debugData));

    const copyDebugStringToClipboard = useCallback(async () => {
        await navigator.clipboard.writeText(featuresDebugData);
    }, [featuresDebugData]);

    return (
        <Alert
            severity={props.alertSeverity}
            sx={{
                width: "100%",
            }}
        >
            <AlertTitle>{props.title}</AlertTitle>
            <Typography variant="body1" style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
                {props.message}
                {featuresDebugData && (
                    <>
                        <br />
                        <br />
                        <b>Provide the following information:</b>
                        <br />
                        {featuresDebugData} <IconButton onClick={copyDebugStringToClipboard} size="small">
                            <ContentCopy fontSize="inherit" />
                        </IconButton>
                    </>
                )}
            </Typography>
        </Alert>
    );
}
