import { DownloadJobStatus } from "types/DownloadJobStatus";

export interface DownloadJobUpdateProgress {
    jobId: string;
    progress: number;
}

export interface DownloadJobUpdateStatus {
    jobId: string;
    jobStatus: DownloadJobStatus;
}

export interface DownloadState {
    downloadJobsProgress: DownloadJobUpdateProgress[];
    downloadJobsStatus: DownloadJobUpdateStatus[];
}

export const initialState: DownloadState = {
    downloadJobsProgress: [],
    downloadJobsStatus: [],
};
