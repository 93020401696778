import { setupListeners } from "@reduxjs/toolkit/query";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import { coreSlice } from "features/core/store/slice";
import { sitesSlice } from "features/sites/store/slice";
import { devicesSlice } from "features/devices/store/slice";
import { liveStreamingSlice } from "features/liveStreaming/store/slice";
import { playbackSlice } from "features/playback/store/slice";
import { messagesSlice } from "features/messages/store/slice";
import { listenerMiddleware } from "./listenerMiddleware";

import { configApi } from "services/config.api";
import { streamingApi } from "services/streamingApi/base.api";
import { graphApi } from "services/graph.api";
import { downloadsSlice } from "features/downloads/store/slice";
import { appFeaturesSlice } from "features/appFeatures/store/slice";

export const store = configureStore({
    reducer: {
        // features
        [coreSlice.name]: coreSlice.reducer,
        [sitesSlice.name]: sitesSlice.reducer,
        [devicesSlice.name]: devicesSlice.reducer,
        [liveStreamingSlice.name]: liveStreamingSlice.reducer,
        [playbackSlice.name]: playbackSlice.reducer,
        [messagesSlice.name]: messagesSlice.reducer,
        [downloadsSlice.name]: downloadsSlice.reducer,
        [appFeaturesSlice.name]: appFeaturesSlice.reducer,

        // services
        [configApi.reducerPath]: configApi.reducer,
        [streamingApi.reducerPath]: streamingApi.reducer,
        [graphApi.reducerPath]: graphApi.reducer,
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(listenerMiddleware.middleware).concat(configApi.middleware).concat(streamingApi.middleware).concat(graphApi.middleware),

    devTools: process.env.NODE_ENV !== "production",
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
