import { EntityId, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { dataApi } from "services/streamingApi/data.api";
import { devicesAdapter } from "./state";

// thunks
const fetchDevicesForSiteAsync = createAsyncThunk("devices/fetchDevicesForSite", async (siteId: EntityId, thunkApi) => {
    // fetch devices for specified site
    const { data, error, isSuccess } = await thunkApi.dispatch(
        dataApi.endpoints.getDevicesBySiteAndCategory.initiate({
            siteId: siteId.toString(),
            category: "video",
        }),
    );

    if (isSuccess) {
        return data;
    }

    throw new Error(`Error while retrieving device data: ${error}`);
});

// slice
export const devicesSlice = createSlice({
    name: "devices",
    initialState: devicesAdapter.getInitialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchDevicesForSiteAsync.fulfilled, (state, action) => {
            devicesAdapter.addMany(state, action.payload);
        });
    },
});

// exports
export const devicesThunks = { fetchDevicesForSiteAsync };
export const devicesActions = devicesSlice.actions;

export default devicesSlice.reducer;
