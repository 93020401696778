import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { initialState } from "./state";
import { RecordingChangedMessage } from "../types/RecordingChangedMessage";
import { JobChangedMessage } from "../types/JobChangedMessage";

// slice
export const downloadsSlice = createSlice({
    name: "downloads",
    initialState: initialState,
    reducers: {
        setJobProcessRecordingChangedAsync: (
            state,
            action: PayloadAction<{
                recordingChangedMessage: RecordingChangedMessage;
            }>,
        ) => {
            let job = state.downloadJobsProgress.find((j) => j.jobId === action.payload.recordingChangedMessage.jobId);
            if (job === undefined) {
                job = {
                    jobId: action.payload.recordingChangedMessage.jobId,
                    progress: action.payload.recordingChangedMessage.progress,
                };
                state.downloadJobsProgress.push(job);
            } else {
                job.progress = action.payload.recordingChangedMessage.progress;
            }
        },
        setJobStatusChangedAsync: (
            state,
            action: PayloadAction<{
                jobChangedMessage: JobChangedMessage;
            }>,
        ) => {
            let job = state.downloadJobsStatus.find((j) => j.jobId === action.payload.jobChangedMessage.jobId);
            if (job === undefined) {
                job = {
                    jobId: action.payload.jobChangedMessage.jobId,
                    jobStatus: action.payload.jobChangedMessage.status,
                };
                state.downloadJobsStatus.push(job);
            } else {
                job.jobStatus = action.payload.jobChangedMessage.status;
            }
        },
    },
});

// exports
export const downloadsActions = downloadsSlice.actions;

export default downloadsSlice.reducer;
