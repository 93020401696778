import { RootState } from "app/store";

// selectors
export const selectThemePaletteMode = (state: RootState) => state.core.themePaletteMode;

export const selectInFullscreenMode = (state: RootState) => state.core.uiMode === "fullscreen" || state.core.uiMode === "fullscreenElement";

export const selectInWindowMode = (state: RootState) => state.core.uiMode === "window";

export const selectHideNavigationalElements = (state: RootState) => state.core.hideNavigationalElements;

export const selectLastWindowModeYOffset = (state: RootState) => state.core.lastWindowModeYOffset;

export const selectShowSidebar = (state: RootState) => state.core.showSidebar;
