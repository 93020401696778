import type { PaletteMode } from "@mui/material";

import { UIMode } from "types/UIMode";

export interface CoreState {
    themePaletteMode: PaletteMode;
    uiMode: UIMode;
    hideNavigationalElements: boolean;
    showSidebar: boolean;
    lastWindowModeYOffset: number;
}

export const initialState: CoreState = {
    themePaletteMode: "light",
    uiMode: "window",
    showSidebar: true,
    hideNavigationalElements: false,
    lastWindowModeYOffset: 0,
};
