import { createSelector } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { RootState } from "app/store";
import { MatchType } from "../types/matchType";
import { AppFeature } from "../types/AppFeature";
import { MsalInstanceService } from "features/auth/MsalInstanceService";

export const selectHasSuccessfullyFetchedFeatures = (state: RootState) => state.appFeatures.hasSuccessfullyFetchedFeatures;

export const selectMyEnabledFeatures = (state: RootState) => state.appFeatures.enabledFeatures;

export const selectEnabledFeaturesErrorResponse = (state: RootState) => state.appFeatures.enabledFeaturesErrorResponse;

export const selectFeaturesDebugData = createSelector([selectMyEnabledFeatures, selectEnabledFeaturesErrorResponse, (state: RootState, data: object | undefined) => data], (enabledFeatures: AppFeature[], errorResponse: FetchBaseQueryError | undefined, data: object | undefined) => {
    const userName = MsalInstanceService.getInstance().getCurrentUserName();

    const debugData = {
        userName,
        enabledFeatures,
        errorResponse,
        data,
    };

    return btoa(JSON.stringify(debugData));
});

export const selectUserHasFeatures = createSelector([selectMyEnabledFeatures, (state: RootState, requestedFeatures: AppFeature[]) => requestedFeatures, (state: RootState, requestedFeatures: AppFeature[], matchType?: MatchType) => matchType], (enabledFeatures: AppFeature[], requestedFeatures: AppFeature[], matchType?: MatchType) => {
    if (!matchType || matchType === MatchType.All) {
        return requestedFeatures.every((feature) => enabledFeatures.includes(feature));
    }

    return requestedFeatures.some((feature) => enabledFeatures.includes(feature));
});
