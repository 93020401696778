import { useEffect, useMemo } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { coreActions } from "features/core/store/slice";
import { selectThemePaletteMode } from "features/core/store/selectors";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { iCoppTheme } from "../iCopp.theme";
import { selectThemeOverridesConfig } from "features/config/store/selectors";

export const useICoppTheme = () => {
    const dispatch = useAppDispatch();
    const themeOverridesConfig = useAppSelector(selectThemeOverridesConfig);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    useEffect(() => {
        dispatch(coreActions.setThemePaletteMode(prefersDarkMode ? "dark" : "light"));
    }, [dispatch, prefersDarkMode]);

    const themePaletteMode = useAppSelector(selectThemePaletteMode);

    const theme = useMemo(() => iCoppTheme(themePaletteMode, themeOverridesConfig), [themePaletteMode, themeOverridesConfig]);

    return theme;
};
