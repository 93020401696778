import { IPublicClientApplication, createStandardPublicClientApplication, Configuration, AccountInfo, InteractionRequiredAuthError, SilentRequest } from "@azure/msal-browser";

export class MsalInstanceService {
    // singleton
    private static _instance: MsalInstanceService | undefined = undefined;

    private constructor() {}

    public static getInstance(): MsalInstanceService {
        if (!this._instance) {
            this._instance = new MsalInstanceService();
        }

        return this._instance;
    }

    // instance
    private _publicClientApp: IPublicClientApplication | undefined;

    // we should always force refresh on the initial request
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#avoiding-interactive-interruptions-in-the-middle-of-a-users-session
    private _shouldForceRefresh: boolean = true;

    public get publicClientApp() {
        if (!this._publicClientApp) throw new Error("No Public Client Application inititalized");

        return this._publicClientApp;
    }

    public async initPublicClientAppAsync(configuration: Configuration): Promise<IPublicClientApplication> {
        const clientApp = await createStandardPublicClientApplication(configuration);

        this._publicClientApp = clientApp;

        return this._publicClientApp;
    }

    public async acquireTokenAsync(scopes: string[]) {
        if (!this._publicClientApp) throw new Error("No Public Client Application inititalized");

        const account = this.getCurrentAccount();

        try {
            const request = {
                scopes,
                account,
                forceRefresh: this._shouldForceRefresh,
            } as SilentRequest;

            const authResult = await this._publicClientApp.acquireTokenSilent(request);

            this._shouldForceRefresh = false;

            return authResult.accessToken;
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return this._publicClientApp.acquireTokenRedirect({
                    scopes: scopes,
                });
            }
        }
    }

    public getCurrentAccount(): AccountInfo | undefined {
        if (!this._publicClientApp) throw new Error("No Public Client Application inititalized");

        const activeAccount = this._publicClientApp.getActiveAccount();
        const accounts = this._publicClientApp.getAllAccounts();

        return activeAccount || accounts[0];
    }

    public getCurrentUserName(): string {
        return this.getCurrentAccount()?.username ?? "Unknown";
    }

    public async logoutAsync(): Promise<void> {
        if (!this._publicClientApp) throw new Error("No Public Client Application inititalized");

        await this._publicClientApp.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }
}
