import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { userApi } from "services/streamingApi/user.api";

export const appFeaturesSlice = createSlice({
    name: "appFeatures",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(userApi.endpoints.getMyEnabledFeatures.matchFulfilled, (state, action) => {
            state.hasSuccessfullyFetchedFeatures = true;
            state.enabledFeaturesErrorResponse = undefined;
            state.enabledFeatures = action.payload;
        });

        builder.addMatcher(userApi.endpoints.getMyEnabledFeatures.matchRejected, (state, action) => {
            // We don't set state.hasSuccessfullyFetchedFeatures to `false`, as we threat one succefull call to fetch user permissions acceptable.
            // Failing to update cached permission will NOT result in showing an error to the user!
            state.enabledFeaturesErrorResponse = action.payload;
        });
    },
});

export const appFeaturesActions = appFeaturesSlice.actions;

export default appFeaturesSlice.reducer;
