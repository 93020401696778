import type { PaletteColorOptions, PaletteMode } from "@mui/material";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import { ThemeOverridesConfiguration } from "types/AppConfig";

export const iCoppTheme = (mode: PaletteMode, themeOverrides?: ThemeOverridesConfiguration) => {
    let generalThemeObject: ThemeOptions = {
        palette: {
            mode: mode,
            primary: {
                main: !themeOverrides?.mainColor ? "#fe7000" : themeOverrides.mainColor,
                contrastText: "#ffffff",
            } as PaletteColorOptions,
            secondary: {
                main: "#6131ad",
            },
            info: {
                main: "#7adec9",
            },
        },
        typography: {
            buttonCondensed: {
                fontSize: ".875rem",
                lineHeight: 1.4,
            },
        },
    };

    if (mode === "dark" && themeOverrides?.mainColor) {
        generalThemeObject = {
            ...generalThemeObject,
            components: {
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            backgroundColor: themeOverrides.mainColor,
                        },
                    },
                },
            },
        };
    }

    const createdTheme = createTheme({
        ...generalThemeObject,
        palette: {
            ...generalThemeObject.palette,
            mode,
        },
    });

    return createdTheme;
};

declare module "@mui/material/styles" {
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        buttonCondensed?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        buttonCondensed: true;
    }
}
