import { createSelector } from "@reduxjs/toolkit";

import { configApi } from "services/config.api";

// selectors
export const selectAppConfig = configApi.endpoints.getConfig.select();

export const selectApplicationInsights = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.applicationInsights);

export const selectMsalConfig = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.msal);

export const selectStreamingApiConfiguration = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.streamingApi);
export const selectStreamingApiBaseUrl = createSelector(selectStreamingApiConfiguration, (streamingApiConfig) => streamingApiConfig?.baseUrl);
export const selectStreamingApiScopes = createSelector(selectStreamingApiConfiguration, (streamingApiConfig) => streamingApiConfig?.scopes || []);

export const selectGraphApiConfiguration = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.graphApi);
export const selectGraphApiBaseUrl = createSelector(selectGraphApiConfiguration, (graphApiConfig) => graphApiConfig?.baseUrl);
export const selectGraphApiScopes = createSelector(selectGraphApiConfiguration, (graphApiConfig) => graphApiConfig?.scopes || []);

export const selectLiveStreamingConfiguration = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.liveStreaming);

export const selectSiteEventsConfiguration = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.siteEvents);

export const selectPlaybackConfiguration = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.playback);

export const selectFeatureManagementConfiguration = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.featureManagement);

export const selectDeeplinkSessionConfig = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.deeplinkSession);

export const selectEnvironmentConfig = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.environment);

export const selectThemeOverridesConfig = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.themeOverrides);

export const selectUnsupportedBrowsersConfig = createSelector(selectAppConfig, (appConfig) => appConfig?.data?.unsupportedBrowsers);
