import { useCallback, useEffect } from "react";

import { useAppDispatch } from "app/hooks";

import { coreActions } from "features/core/store/slice";

export const useFullscreenApi = (onExitFullscreen: (e: Event) => void) => {
    const dispatch = useAppDispatch();

    const onFullScreenChange = useCallback(
        (e: Event) => {
            if (!document.fullscreenElement) {
                onExitFullscreen(e);
            } else if (document.fullscreenElement !== document.body) {
                // the element is already set to fullscreen, this dispatch notifies the store of that
                dispatch(coreActions.setUIMode("fullscreenElement"));
            }
        },
        [dispatch, onExitFullscreen],
    );

    useEffect(() => {
        document.addEventListener("fullscreenchange", onFullScreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", onFullScreenChange);
        };
    }, [onFullScreenChange]);
};
