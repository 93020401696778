export interface Message {
    messageType: string;
    args: unknown[];
}

export interface MessagesState {
    messages: Message[];
}

export const initialState: MessagesState = {
    messages: [],
};
