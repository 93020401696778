import { SignalRHub } from "../types/SignalRHub";

export enum BatchDownloadClientMethodNames {
    RecordingChanged = "RecordingChanged",
    JobChanged = "JobChanged",
}

export class BatchDownloadHub implements SignalRHub {
    hubName: string = "BatchDownload";
    hubMethodNames = [];
    clientMethodNames = [BatchDownloadClientMethodNames.RecordingChanged, BatchDownloadClientMethodNames.JobChanged];
}
