import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./state";
import { IncomingMessage } from "../types/IncomingMessage";

// slice
export const messagesSlice = createSlice({
    name: "messages",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        messageReceived: (state, action: PayloadAction<IncomingMessage>) => {
            // empty reducer needed to generate action used in listeners.ts files
        },
    },
});

// exports
export const messagesActions = messagesSlice.actions;

export default messagesSlice.reducer;
