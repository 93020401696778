import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { createBaseQueryFnWithBaseUrlFromStore } from "features/core/baseUrlFromStoreQuery";
import { selectGraphApiBaseUrl, selectGraphApiScopes } from "features/config/store/selectors";
import { MsalInstanceService } from "features/auth/MsalInstanceService";

const configuredBaseQuery = createBaseQueryFnWithBaseUrlFromStore(
    fetchBaseQuery({
        baseUrl: undefined,
        prepareHeaders: async (headers, api) => {
            headers.set("Content-Type", "application/json");

            const msalInstanceService = MsalInstanceService.getInstance();
            const graphApiScopes = selectGraphApiScopes(api.getState() as RootState);

            const accessToken = await msalInstanceService.acquireTokenAsync(graphApiScopes);

            if (accessToken) {
                headers.set("authorization", `Bearer ${accessToken}`);
            }

            return headers;
        },
    }),
    selectGraphApiBaseUrl,
);

export const graphApi = createApi({
    reducerPath: "graphApi",
    baseQuery: configuredBaseQuery,
    endpoints: (builder) => ({
        getUserDisplayName: builder.query<string, void>({
            query: () => "/me?$select=displayName",
            transformResponse: async (response: { displayName: string }) => {
                return response.displayName;
            },
        }),
        getUserPhoto: builder.query<string, void>({
            query: () => ({
                url: `/me/photos/120x120/$value`,
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: async (response: Blob) => {
                return URL.createObjectURL(response);
            },
            transformErrorResponse: (response: { status: string | number }) => response.status,
        }),
    }),
});

export const { useGetUserDisplayNameQuery, useGetUserPhotoQuery } = graphApi;
