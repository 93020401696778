import { streamingApi } from "./base.api";
import Site from "types/Site";
import { Device } from "types/Device";
import { EntityId } from "@reduxjs/toolkit";

const BASE_PATH = "/data";

export const dataApi = streamingApi.injectEndpoints({
    endpoints: (build) => ({
        getSiteById: build.query<Site, EntityId>({
            query: (siteId) => `${BASE_PATH}/sites/${siteId}`,
        }),
        searchSites: build.query<Array<Site>, string>({
            query: (query) => `${BASE_PATH}/sites/search?q=${query}`,
        }),
        getDevicesBySiteAndCategory: build.query<
            Array<Device>,
            {
                siteId: string;
                category: string;
            }
        >({
            query: (args) => `${BASE_PATH}/sites/${args.siteId}/devices/category/${args.category}`,
            transformResponse: (response: Array<Device>, meta, arg) => {
                // save site id with response object
                response.forEach((x) => (x.siteId = arg.siteId));

                return response;
            },
        }),
    }),
    overrideExisting: true,
});

export const { useGetSiteByIdQuery, useSearchSitesQuery, useGetDevicesBySiteAndCategoryQuery } = dataApi;
