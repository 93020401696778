import { EntityId, createSelector } from "@reduxjs/toolkit";

import dayjs from "dayjs";

import { RootState } from "app/store";

import { selectPlaybackConfiguration } from "features/config/store/selectors";

import { PlaybackConfiguration } from "types/AppConfig";

const selectSiteId = (state: RootState) => state.playback.siteId;

const selectDeviceIds = (state: RootState) => state.playback.deviceIds;

const selectStartDateTime = (state: RootState) => state.playback.startDateTime;

const selectEndDateTime = (state: RootState) => state.playback.endDateTime;

const selectStartDateTimeError = createSelector([selectStartDateTime, selectEndDateTime], (startDateTime?: string, endDateTime?: string) => {
    if (!startDateTime) return;

    const startDateTimeDayJs = dayjs(startDateTime);
    const endDateTimeDayJs = dayjs(endDateTime);

    if (!startDateTimeDayJs.isValid()) {
        return "Invalid date specified";
    } else if (endDateTimeDayJs?.isValid() && !startDateTimeDayJs.isBefore(endDateTimeDayJs)) {
        return "Date must be before TO date";
    }
});

const selectEndDateTimeError = createSelector([selectPlaybackConfiguration, selectStartDateTime, selectEndDateTime], (playbackConfiguration: PlaybackConfiguration | undefined, startDateTime?: string, endDateTime?: string) => {
    if (!endDateTime) return;

    const maximumNumberOfDays = playbackConfiguration?.maxNumberOfDays ?? 6;
    const startDateTimeDayJs = dayjs(startDateTime);
    const endDateTimeDayJs = dayjs(endDateTime);

    if (!endDateTimeDayJs.isValid()) {
        return "Invalid date specified";
    } else if (startDateTimeDayJs?.isValid() && !endDateTimeDayJs.isAfter(startDateTimeDayJs)) {
        return "Date must be after FROM date";
    } else if (startDateTimeDayJs?.isValid() && endDateTimeDayJs.diff(startDateTime, "day") > maximumNumberOfDays) {
        return `Your selection is bigger than ${maximumNumberOfDays} days, please correct`;
    }
});

const selectHasPlaybackSessionRequirementsFulfilled = createSelector([selectSiteId, selectStartDateTimeError, selectEndDateTimeError], (siteId?: EntityId, startDateTimeError?: string, endDateTimeError?: string) => siteId && !startDateTimeError && !endDateTimeError);

export const playbackSelectionSelectors = {
    selectSiteId,
    selectDeviceIds,
    selectStartDateTime,
    selectEndDateTime,
    selectStartDateTimeError,
    selectEndDateTimeError,
    selectHasPlaybackSessionRequirementsFulfilled,
};
