import { messagesActions } from "features/messages/store/slice";
import { RecordingChangedMessage } from "features/downloads/types/RecordingChangedMessage";

import type { AppStartListening } from "types/AppStartListening";
import { downloadsActions } from "./slice";
import { JobChangedMessage } from "features/downloads/types/JobChangedMessage";
import { BatchDownloadClientMethodNames } from "features/messages/hubs/BatchDownloadHub";
import { RecordingStatus } from "types/RecordingStatus";
import { DownloadJobStatus } from "types/DownloadJobStatus";

export const addDownloadListeners = (startListening: AppStartListening) => {
    // Listen for RecordingChanged messages
    startListening({
        predicate: (action) => {
            return messagesActions.messageReceived.match(action) && action.payload.messageType === BatchDownloadClientMethodNames.RecordingChanged;
        },
        effect: async (action, listenerApi) => {
            if (messagesActions.messageReceived.match(action)) {
                const [jobId, deviceId, recordingId, progress, message, status] = action.payload.args as [string, string, string, number, string, RecordingStatus];

                listenerApi.dispatch(
                    downloadsActions.setJobProcessRecordingChangedAsync({
                        recordingChangedMessage: {
                            jobId,
                            deviceId,
                            recordingId,
                            progress,
                            message,
                            status,
                        } as RecordingChangedMessage,
                    }),
                );
            }
        },
    });

    // Listen for JobChanged messages
    startListening({
        predicate: (action) => {
            return messagesActions.messageReceived.match(action) && action.payload.messageType === BatchDownloadClientMethodNames.JobChanged;
        },
        effect: async (action, listenerApi) => {
            if (messagesActions.messageReceived.match(action)) {
                const [jobId, message, status] = action.payload.args as [string, string, DownloadJobStatus];

                const jobChangedMessage: JobChangedMessage = {
                    jobId,
                    message,
                    status,
                };

                await listenerApi.dispatch(
                    downloadsActions.setJobStatusChangedAsync({
                        jobChangedMessage,
                    }),
                );
            }
        },
    });
};
