import { createListenerMiddleware } from "@reduxjs/toolkit";

import type { AppStartListening } from "types/AppStartListening";

import { addCoreListeners } from "features/core/store/listeners";
import { addDevicesListeners } from "features/devices/store/listeners";
import { addLiveStreamingListeners } from "features/liveStreaming/store/listeners";
import { addPlaybackListeners } from "features/playback/store/listeners";
import { addDownloadListeners } from "features/downloads/store/listeners";

export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

addCoreListeners(startAppListening);
addDevicesListeners(startAppListening);
addLiveStreamingListeners(startAppListening);
addPlaybackListeners(startAppListening);
addDownloadListeners(startAppListening);
