import { EntityId, createSelector } from "@reduxjs/toolkit";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";

import { RootState } from "app/store";
import { recordingAdapter } from "./state";

import { UserPlayMode } from "../types/UserPlayMode";
import { ContinuousPlaybackWindow } from "../types/ContinuousPlaybackWindow";
import { getRecordingWithPlaybackState } from "./helpers";

import { Recording } from "types/Recording";
import { selectPlaybackConfiguration } from "features/config/store/selectors";
import { PlaybackConfiguration } from "types/AppConfig";
import { Device } from "types/Device";
import { selectAllDevices } from "features/devices/store/selectors";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const selectSessionId = (state: RootState) => state.playback.sessionId;

const selectCurrentPlaybackTimeStamp = (state: RootState) => {
    if (state.playback.playbackStartDateTime) {
        return dayjs(state.playback.playbackStartDateTime).add(state.playback.playbackElapsedTimeInMs, "milliseconds").valueOf();
    } else {
        return dayjs(state.playback.startDateTime).valueOf();
    }
};

const { selectTotal: selectTotalRecordings, selectAll: selectAllRecordings, selectById: selectRecordingById } = recordingAdapter.getSelectors((state: RootState) => state.playback.recordings);

// https://github.com/reduxjs/reselect#q-can-i-share-a-selector-across-multiple-component-instances
const selectRecordingsByDeviceIdFactory = () => createSelector([selectAllRecordings, (state: RootState, deviceId: EntityId) => deviceId], (recordings: Recording[], deviceId: EntityId) => recordings.filter((x) => x.deviceId === deviceId));

const selectBufferDateTimeRange = (state: RootState) => {
    // from current playback position + X seconds
    const currentPlaybackPosition = dayjs(state.playback.playbackStartDateTime).add(state.playback.playbackElapsedTimeInMs, "milliseconds");

    return {
        startDateTime: currentPlaybackPosition.toISOString(),
        endDateTime: currentPlaybackPosition.add(state.playback.bufferWindowInSeconds, "seconds").toISOString(),
    };
};

const selectDeviceRecordingWithPlaybackStateByIdFactory = () =>
    createSelector([selectRecordingById, selectCurrentPlaybackTimeStamp, selectBufferDateTimeRange], (recording: Recording | undefined, currentPlaybackTimeStamp: number, bufferWindow: { startDateTime: string; endDateTime: string }) => {
        if (!recording) return undefined;

        const bufferStartDateTimeTicks = dayjs(bufferWindow.startDateTime).valueOf();
        const bufferEndDateTimeTicks = dayjs(bufferWindow.endDateTime).valueOf();

        return getRecordingWithPlaybackState(bufferStartDateTimeTicks, bufferEndDateTimeTicks, currentPlaybackTimeStamp, recording);
    });

const selectUserRequestedPlayMode = (state: RootState) => state.playback.userRequestedPlayMode;

const selectRecordingsIdsInPlaybackWindow = createSelector([selectAllRecordings, selectCurrentPlaybackTimeStamp], (recordings: Recording[], currentPlaybackTimeStamp: number) => {
    return recordings
        .filter((recording) => {
            const startTimeTicks = dayjs(recording.startTime).valueOf();
            const stopTimeTicks = dayjs(recording.stopTime).valueOf();

            return startTimeTicks <= currentPlaybackTimeStamp && stopTimeTicks >= currentPlaybackTimeStamp;
        })
        .map((x) => x.id);
});

const selectAllRecordingsWithDeviceData = createSelector([selectAllRecordings, selectAllDevices], (recordings: Recording[], devices: Device[]) => {
    return recordings.map((x) => {
        return {
            ...x,
            deviceName: devices.find((d) => d.id === x.deviceId)?.name,
        };
    });
});

const selectReadyForPlaybackStatistics = createSelector([selectAllRecordings, selectCurrentPlaybackTimeStamp, (state: RootState) => state.playback.recordingsReadyForPlayback, (state: RootState) => state.playback.recordingsFailedToLoad, (state: RootState) => state.playback.recordingsWaiting], (recordings: Recording[], currentPlaybackTimeStamp: number, recordingsReadyForPlayback: EntityId[], recordingsFailedToLoad: EntityId[], recordingsWaiting: EntityId[]) => {
    const recordingsWhichShouldBePlaying = recordings.filter((recording) => {
        const startTimeTicks = dayjs(recording.startTime).valueOf();
        const stopTimeTicks = dayjs(recording.stopTime).valueOf();

        return startTimeTicks <= currentPlaybackTimeStamp && stopTimeTicks >= currentPlaybackTimeStamp;
    });

    const readyForPlayback = recordingsWhichShouldBePlaying.filter((recording) => recordingsReadyForPlayback.includes(recording.recordingId)).length;

    const failedToLoad = recordingsWhichShouldBePlaying.filter((recording) => recordingsFailedToLoad.includes(recording.recordingId)).length;

    const waiting = recordingsWhichShouldBePlaying.filter((recording) => recordingsWaiting.includes(recording.recordingId)).length;

    return {
        totalRecordings: recordingsWhichShouldBePlaying.length,
        totalReadyForPlayback: readyForPlayback,
        totalFailedToLoad: failedToLoad,
        totalWaiting: waiting,
        percentageReadyForPlayback: ((readyForPlayback + failedToLoad) / recordingsWhichShouldBePlaying.length) * 100,
        ready: recordingsWhichShouldBePlaying.length - readyForPlayback - failedToLoad <= 0,
    };
});

const selectPlaybackPlaying = createSelector(
    [selectUserRequestedPlayMode, selectReadyForPlaybackStatistics],
    (
        userRequestedPlayMode: UserPlayMode,
        readyForPlaybackStatistics: {
            totalRecordings: number;
            totalReadyForPlayback: number;
            percentageReadyForPlayback: number;
            ready: boolean;
        },
    ) => {
        // check if user wants to play
        if (userRequestedPlayMode !== "play" && userRequestedPlayMode !== "resume") return false;

        return readyForPlaybackStatistics.ready;
    },
);

const selectIsWaitingForRecordingsToBecomeReady = createSelector([selectUserRequestedPlayMode, selectPlaybackPlaying], (userRequestedPlayMode: UserPlayMode, isPlaying: boolean) => (userRequestedPlayMode === "play" || userRequestedPlayMode === "resume") && !isPlaying);

const selectRecordingFailedToLoadByIdFactory = () => createSelector([selectRecordingById, (state: RootState) => state.playback.recordingsFailedToLoad], (recording: Recording | undefined, recordingsFailedToLoad: EntityId[]) => recording && recordingsFailedToLoad.some((x) => x === recording.recordingId));

const selectPlaybackStartDateTime = (state: RootState) => state.playback.playbackStartDateTime || state.playback.startDateTime;

const selectContinuousPlaybackWindow = createSelector([selectPlaybackConfiguration, (state: RootState) => state.playback.continuousPlaybackStartDateTime, (state: RootState) => state.playback.continuousPlaybackEndDateTime], (playbackConfiguration: PlaybackConfiguration | undefined, continuousPlaybackStartDateTime: string | undefined, continuousPlaybackEndDateTime: string | undefined): ContinuousPlaybackWindow => {
    if (playbackConfiguration?.enableContinuousPlaybackWindowFeature && continuousPlaybackStartDateTime && continuousPlaybackEndDateTime) {
        return {
            hasContinuousPlaybackWindow: true,
            continuousPlaybackStartDateTime,
            continuousPlaybackEndDateTime,
        };
    }

    return {
        hasContinuousPlaybackWindow: false,
        continuousPlaybackStartDateTime: undefined,
        continuousPlaybackEndDateTime: undefined,
    };
});

const selectBufferWindow = createSelector(
    [selectContinuousPlaybackWindow, (state: RootState) => state.playback.playbackStartDateTime, (state: RootState) => state.playback.playbackElapsedTimeInMs],
    (
        continuousPlaybackWindow: ContinuousPlaybackWindow,
        playbackStartDateTime: string | undefined,
        playbackElapsedTimeInMs: number,
    ):
        | {
              startDateTime: string | undefined;
              endDateTime: string | undefined;
          }
        | undefined => {
        const startDateTime = continuousPlaybackWindow.hasContinuousPlaybackWindow ? continuousPlaybackWindow.continuousPlaybackStartDateTime : playbackStartDateTime;

        const maxEndDateTime = continuousPlaybackWindow.continuousPlaybackEndDateTime || playbackStartDateTime ? dayjs.max(dayjs(continuousPlaybackWindow.continuousPlaybackEndDateTime || 0), dayjs(playbackStartDateTime || 0).add(playbackElapsedTimeInMs, "milliseconds")) : undefined;

        return {
            startDateTime,
            endDateTime: maxEndDateTime ? maxEndDateTime.toISOString() : undefined,
        };
    },
);

const selectCanReuseExistingStreamByRecordingIdFactory = () =>
    createSelector([selectRecordingById, selectContinuousPlaybackWindow, (state: RootState) => state.playback.playbackStartDateTime, (state: RootState) => state.playback.playbackElapsedTimeInMs, (state: RootState) => state.playback.playbackStartDateTimeChangedTimestamp], (recording: Recording | undefined, continuousPlaybackWindow: ContinuousPlaybackWindow, playbackStartDateTime: string | undefined, playbackElapsedTimeInMs: number, playbackStartDateTimeChangedTimestamp: number | undefined) => {
        let canReuseStream = false;
        let currentTime = 0;

        const playbackStartDateTimeDayjs = dayjs(playbackStartDateTime);

        if (
            // we have a continuous playback window
            continuousPlaybackWindow.hasContinuousPlaybackWindow &&
            // we have a recording
            recording
        ) {
            const recordingStartTime = dayjs(recording.startTime);
            const recordingStopTime = dayjs(recording.stopTime);
            const coulanceSeconds = 2;

            const recordingWasPlayingInContinuousPlaybackWindow = recordingStartTime.add(coulanceSeconds, "second").isSameOrBefore(continuousPlaybackWindow.continuousPlaybackEndDateTime) && recordingStopTime.isSameOrAfter(continuousPlaybackWindow.continuousPlaybackEndDateTime);

            if (
                // the recording was already playing inside continuous playback window
                recordingWasPlayingInContinuousPlaybackWindow ||
                // current playback position falls in continuous playback window
                playbackStartDateTimeDayjs.add(playbackElapsedTimeInMs, "milliseconds").isBetween(continuousPlaybackWindow.continuousPlaybackStartDateTime, continuousPlaybackWindow.continuousPlaybackEndDateTime, "seconds", "[)")
            ) {
                // determine currentTime
                if (playbackStartDateTimeDayjs.isSameOrBefore(recordingStartTime)) {
                    currentTime = 0;
                    canReuseStream = true;
                } else if (playbackStartDateTimeDayjs.isBetween(recordingStartTime, recordingStopTime, "seconds", "[]")) {
                    currentTime = playbackStartDateTimeDayjs.diff(recordingStartTime, "seconds");
                    canReuseStream = true;
                }
            }
        }

        return {
            canReuseStream,
            currentTime,
            playbackStartDateTimeChangedTimestamp,
        };
    });

export const playbackSessionSelectors = {
    // data
    selectSessionId,
    selectAllRecordings,
    selectTotalRecordings,
    selectRecordingById,
    selectRecordingsByDeviceIdFactory,
    selectAllRecordingsWithDeviceData,

    // timeline
    selectCurrentPlaybackTimeStamp,
    selectPlaybackStartDateTime,
    selectUserRequestedPlayMode,
    selectPlaybackPlaying,

    // buffering and loading
    selectBufferDateTimeRange,
    selectRecordingsIdsInPlaybackWindow,
    selectReadyForPlaybackStatistics,
    selectIsWaitingForRecordingsToBecomeReady,
    selectRecordingFailedToLoadByIdFactory,
    selectDeviceRecordingWithPlaybackStateByIdFactory,

    // continuous playback
    selectContinuousPlaybackWindow,
    selectBufferWindow,
    selectCanReuseExistingStreamByRecordingIdFactory,
};
