import { RootState } from "app/store";
import { getSiteDeviceSelectionBySiteId } from "./helpers";
import { EntityId } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const selectActiveSiteId = (state: RootState) => state.liveStreaming.activeSiteId;

export const selectSiteDeviceSelections = (state: RootState) => state.liveStreaming.siteDeviceSelections;

export const selectActiveSiteDeviceSelection = (state: RootState) => (state.liveStreaming.activeSiteId && getSiteDeviceSelectionBySiteId(state.liveStreaming, state.liveStreaming.activeSiteId)) || undefined;

export const selectFullscreenDeviceId = (state: RootState) => state.liveStreaming.fullscreenDeviceId;

export const selectSelectedDeviceIdsForActiveSite = (state: RootState) => (state.liveStreaming.activeSiteId && getSiteDeviceSelectionBySiteId(state.liveStreaming, state.liveStreaming.activeSiteId)?.selectedDevices?.map((item) => item.deviceId)) || undefined;

export const selectDeviceSelectionForSiteIsDoneByPaging = (state: RootState, siteId: EntityId) => getSiteDeviceSelectionBySiteId(state.liveStreaming, siteId)?.deviceSelectionIsDoneByPaging || false;

export const selectDeviceSelectionForSitePageNumber = (state: RootState, siteId: EntityId) => getSiteDeviceSelectionBySiteId(state.liveStreaming, siteId)?.pageNumber || 1;

export const selectHasBadNetworkConnection = (state: RootState, minutesBack: number) => {
    const activeSiteSelection = (state.liveStreaming.activeSiteId && getSiteDeviceSelectionBySiteId(state.liveStreaming, state.liveStreaming.activeSiteId)) || undefined;
    if (!activeSiteSelection || !activeSiteSelection.devicesWithBadConnection) {
        return false;
    }
    const minutesAgo = dayjs().subtract(minutesBack, "minutes");

    return activeSiteSelection.devicesWithBadConnection.some((device) => dayjs(device.badNetworkDetectedTime).isAfter(minutesAgo));
};
