import { AppFeature } from "features/appFeatures/types/AppFeature";
import { streamingApi } from "./base.api";

const BASE_PATH = "/user";

export const userApi = streamingApi.injectEndpoints({
    endpoints: (build) => ({
        getMyEnabledFeatures: build.query<AppFeature[], void>({
            query: () => `${BASE_PATH}/users/me/Features/enabled`,
        }),
    }),
    overrideExisting: true,
});

export const { useGetMyEnabledFeaturesQuery } = userApi;
