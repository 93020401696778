import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ConfirmProvider } from "material-ui-confirm";

import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";

import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

import { SnackbarProvider } from "notistack";
import { IdleTimerProvider } from "react-idle-timer";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// include the following locales in our build
import "dayjs/locale/de";
import "dayjs/locale/nl";
import "dayjs/locale/en-gb";
import "dayjs/locale/en";

import dayjs from "dayjs";

import { useAppDispatch, useAppSelector } from "app/hooks";

import { AppFeatureLoader } from "features/appFeatures/AppFeatureLoader";
import AppTitle from "features/core/AppTitle";
import { FeatureFlagGuard } from "features/appFeatures/FeatureFlagGuard";
import { AppFeature } from "features/appFeatures/types/AppFeature";
import { MsalInstanceService } from "features/auth/MsalInstanceService";
import { selectMsalConfig, selectFeatureManagementConfiguration, selectDeeplinkSessionConfig } from "features/config/store/selectors";
import AutoCloseDialog from "features/core/AutoCloseDialog";
import { GlobalErrorMessage } from "features/core/GlobalErrorMessage";
import UnsupportedBrowserPrompt from "features/core/UnsupportedBrowserPrompt";
import { coreActions } from "features/core/store/slice";
import PreferredEnvironmentPrompt from "features/core/PreferredEnvironmentPrompt";

import { useApplicationInsights } from "hooks/useApplicationInsights";
import { useFullscreenApi } from "hooks/useFullscreenApi";
import { useICoppTheme } from "hooks/useICoppTheme";

import { useGetConfigQuery } from "services/config.api";

const selectedLocale = dayjs.locale(navigator.language.toLowerCase() || "en-gb");

// router configuration
export default function App() {
    const dispatch = useAppDispatch();

    // load AppConfig
    const { isLoading: isLoadingConfig } = useGetConfigQuery();

    // application insights
    const { reactPlugin } = useApplicationInsights();

    // load Feature Management config
    const featureManagementConfig = useAppSelector(selectFeatureManagementConfiguration);
    const deeplinkSessionConfig = useAppSelector(selectDeeplinkSessionConfig);

    // MSAL
    const [publicClientApplication, setPublicClientApplication] = useState<IPublicClientApplication | null>();
    const msalConfig = useAppSelector(selectMsalConfig);
    useEffect(() => {
        const initMsalInstanceService = async () => {
            if (msalConfig) {
                const pca = await MsalInstanceService.getInstance().initPublicClientAppAsync(msalConfig);

                setPublicClientApplication(pca);
            }
        };

        initMsalInstanceService();
    }, [msalConfig]);

    // UI
    const theme = useICoppTheme();

    // enable browser fullscreen API and make sure when user exits we update the UI mode
    useFullscreenApi(() => dispatch(coreActions.setUIMode("window")));

    return (
        <AppInsightsErrorBoundary onError={() => <GlobalErrorMessage title="Uncaught error" message="There was an uncaught error while using iCOPP. Please contact the service desk." alertSeverity="error" />} appInsights={reactPlugin}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={selectedLocale}>
                {isLoadingConfig && (
                    <Box sx={{ width: "100%" }}>
                        <LinearProgress />
                    </Box>
                )}

                {publicClientApplication && (
                    <MsalProvider instance={publicClientApplication}>
                        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                            <ThemeProvider theme={theme}>
                                <SnackbarProvider
                                    autoHideDuration={3000}
                                    anchorOrigin={{
                                        horizontal: "right",
                                        vertical: "bottom",
                                    }}
                                >
                                    <ConfirmProvider>
                                        <IdleTimerProvider
                                            startManually={true} // start timer manually
                                            timeout={deeplinkSessionConfig?.defaultSessionTimeInMs} // max iddle time before onIdle is called if started
                                            promptBeforeIdle={deeplinkSessionConfig?.promptBeforeClosureInMs} //max idle time minus promptBeforeIdle is the time the dialog is shown
                                            onIdle={() => {
                                                window.close();
                                            }}
                                            eventsThrottle={500}
                                        >
                                            <FeatureFlagGuard accessibleWithFeatureFlags={[AppFeature.FrontEndUnsupportedBrowserPrompt]}>
                                                <UnsupportedBrowserPrompt />
                                            </FeatureFlagGuard>
                                            <PreferredEnvironmentPrompt />
                                            <AutoCloseDialog />
                                            <AppTitle />

                                            <AppFeatureLoader refreshFeatureIntervalInMs={featureManagementConfig?.cacheDurationInMs}>
                                                <Box sx={{ display: "flex" }}>
                                                    <CssBaseline enableColorScheme />
                                                    <FeatureFlagGuard accessibleWithFeatureFlags={[AppFeature.FrontEndAccess]} withAuthentication>
                                                        <Outlet />
                                                    </FeatureFlagGuard>
                                                </Box>
                                            </AppFeatureLoader>
                                        </IdleTimerProvider>
                                    </ConfirmProvider>
                                </SnackbarProvider>
                            </ThemeProvider>
                        </MsalAuthenticationTemplate>
                    </MsalProvider>
                )}
            </LocalizationProvider>
        </AppInsightsErrorBoundary>
    );
}
