//https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#constructing-a-dynamic-base-url-using-redux-state
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { RootState } from "app/store";

export function createBaseQueryFnWithBaseUrlFromStore(rawBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, baseUrlSelector: (state: RootState) => string | unknown): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> {
    return async (args, api, extraOptions) => {
        // get correct base url
        const baseUrl = baseUrlSelector(api.getState() as RootState);

        if (typeof baseUrl !== "string" || !baseUrl || baseUrl.length === 0) throw new Error("Incorrect base url");

        // alter request url
        const relativeUrl = typeof args === "string" ? args : args.url; // eg: /data/sites?filter=99999
        const absoluteUrl = `${baseUrl.replace(/\/$/, "")}${relativeUrl}`; // eg: https://api.streaming-dev.b-securite.com/data/sites?filter=99999

        const adjustedArgs = typeof args === "string" ? absoluteUrl : { ...args, url: absoluteUrl };

        // run query
        return rawBaseQuery(adjustedArgs, api, extraOptions);
    };
}
